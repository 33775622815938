import PieChartCard from "./components/PieChartCard";
import { MdBarChart, MdAnalytics, MdFavorite, MdInventory, MdLocalShipping,  MdPayments, MdPeopleOutline} from "react-icons/md";
import Widget from "components/widget/Widget";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import Card from "components/card";
import Banner from "./components/Banner";
import HistoryCard from "./components/HistoryCard";

const Dashboard = () => {
  return (
    <div className="">
      <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      <Banner />
      {/* Card widget */}

      </div>
      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1"> 
          <HistoryCard/>
      </div>
      </div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdFavorite className="h-7 w-7" />}
          title={"Likes"}
          subtitle={"4 B"}
        />
         <Widget
          icon={<MdPeopleOutline className="h-7 w-7" />}
          title={"Followers"}
          subtitle={"35 M"}
        />
         <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Subscribers"}
          subtitle={"25 M"}
        />
        <Widget
          icon={<MdInventory className="h-6 w-6" />}
          title={"Total Projects"}
          subtitle={"40"}
        />
        <Widget
          icon={<MdLocalShipping className="h-6 w-6" />}
          title={"Total Invoices"}
          subtitle={"64"}
        />
        <Widget
          icon={<MdPayments className="h-6 w-6" />}
          title={"Payment Balance"}
          subtitle={"$3400"}
        />

        {/* <a href="#">
        <Card extra="!flex-row flex-grow items-center rounded-[20px] bg-brand-900   text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
              <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white">
                <MdAnalytics className="h-7 w-7" />
                </span>
              </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
              <p className="font-dm text-sm font-medium text-gray-600">{}</p>
              <h4 className="text-xl font-bold text-white dark:text-white">
                Generate Report
              </h4>
            </div>
          </Card>
          </a> */}
        
      </div>


      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
      </div>




      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        
      {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div> */}
        
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

       

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>

       {/* Charts */}

       {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}
    </div>
  );
};

export default Dashboard;
