import Banner from "../default/components/Banner";
import List from "./components/List";
import CalenderImg from "assets/img/tools/calender.png";
import FormImg from "assets/img/tools/form.png";
import TimeImg from "assets/img/tools/time.png";
import ContractImg from "assets/img/tools/contract.png";
import Widget from "components/widget/Widget";
import { MdCalendarViewMonth, MdEmail, MdForum, MdTask } from "react-icons/md";
import { IoMdClock, IoMdContract } from "react-icons/io";
import { IoFileTray } from "react-icons/io5";
import { Link } from "react-router-dom";
const Support = () => {
  return (
    <div className="">
      <div className="mt-3 grid h-full grid-cols-1">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      {/* <Banner title="Coming soon..."  /> */}


      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-6">
        <Widget
          icon={<MdCalendarViewMonth className="h-7 w-7" />}
          title={""}
          subtitle={"Calendar"}
        />
        <a href="/admin/toolkit/contract">
        <Widget
          icon={<IoMdContract className="h-7 w-7" />}
          title={""}
          subtitle={"Contracts"}
        />
        </a>
        <Widget
          icon={<MdForum className="h-7 w-7" />}
          title={""}
          subtitle={"Forms"}
        />
        <Widget
          icon={<IoMdClock className="h-7 w-7" />}
          title={""}
          subtitle={"Time Tracker"}
        />
        <Widget
          icon={<MdEmail className="h-7 w-7" />}
          title={""}
          subtitle={"ESignature"}
        />
        <Widget
          icon={<IoFileTray className="h-7 w-7" />}
          title={""}
          subtitle={"Files"}
        />
        <Widget
          icon={<MdTask className="h-7 w-7" />}
          title={""}
          subtitle={"Tasks"}
        />
      </div>  

      
      </div>
     


      </div>
    
    </div>
  );
};

export default Support;
