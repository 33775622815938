import Banner from "../default/components/Banner";
import Card from "components/card";
import InstgramImg from "assets/img/integration/instagram.png";
import MessengerImg from "assets/img/integration/messenger.png";
import GmailImg from "assets/img/integration/gmail.png";
import WhatsappImg from "assets/img/integration/whatsapp.png";
import LineImg from "assets/img/integration/line.png";
import ViberImg from "assets/img/integration/viber.png";
import LinkedinImg from "assets/img/integration/linkedin.png";
import TelegramImg from "assets/img/integration/telegram.png";
import SlackImg from "assets/img/integration/slack.png";
import VkImg from "assets/img/integration/vk.png";
import TwitterImg from "assets/img/integration/twitter.png";
import SkypeImg from "assets/img/integration/skype.png";


import Widget from "components/widget/Widget";
import { MdCalendarViewMonth, MdEmail, MdForum, MdTask } from "react-icons/md";
import { IoLogoFacebook, IoLogoLinkedin, IoMdClock, IoMdContract } from "react-icons/io";
import { IoFileTray, IoLogoInstagram, IoLogoSkype, IoLogoSlack, IoLogoWhatsapp } from "react-icons/io5";
const IntegrationView = () => {
  return (
    <div className="">
      <div className="mt-3 grid h-full grid-cols-1">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      <Banner title="All Notifications, One inbox"   desc ="Centralize all your social messaging apps in one place"  image="" link1 ="#" link2 ="#" buttonLabel1="Connect Your Apps" buttonLabel2="Watch video"  />

        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          Select apps to integrate
          </h4>
        </div>

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-6">
        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={MessengerImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Messenger
                </h4>
            </div>
        </Card>     

        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={InstgramImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Instagram
                </h4>
            </div>
        </Card> 

         <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={WhatsappImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Whatsapp
                </h4>
            </div>
        </Card> 

        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={TelegramImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Telegram
                </h4>
            </div>
        </Card> 
        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={LineImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Line
                </h4>
            </div>
        </Card>   
        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={ViberImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Viber
                </h4>
            </div>
        </Card>  

        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={LinkedinImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Linkdin
                </h4>
            </div>
        </Card> 

        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={SlackImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Slack
                </h4>
            </div>
        </Card>  
        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={SkypeImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Skype
                </h4>
            </div>
        </Card>   
        <Card extra="!flex-row flex-grow items-center rounded-[20px]">
            <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
                <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
                <span className="flex items-center text-brand-500 dark:text-white"> 
                    <img src={VkImg} alt="" className="h-10 w-30" />
                </span>
                </div>
            </div>

            <div className="h-50 ml-4 flex w-auto flex-col justify-center">
                <p className="font-dm text-sm font-medium text-gray-600"></p>
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Vk
                </h4>
            </div>
        </Card> 
     

   
      </div>  

      
      </div>
     


      </div>
    
    </div>
  );
};

export default IntegrationView;
