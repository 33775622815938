import { IoDownload, IoLogoFacebook, IoLogoInstagram, IoLogoTiktok, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoShare } from 'react-icons/io5';
import { MdModeEdit, MdShare } from 'react-icons/md';
const Profile = (props) => {
    const { title , desc , link , image } = props ;

    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();

    let startDate = `${ new Date().getDate()}-${new Date().getMonth() + 1 }-${new Date().getFullYear()}  `;
    let endDate = `  ${ new Date().getDate()}-${new Date().getMonth() + 2 }-${new Date().getFullYear()} `;
    return (
      <div
        className="flex w-full flex-col rounded-[20px] bg-cover px-[30px] py-[30px] md:px-[30px] md:py-[30px]"
        style={{ background : '#3f2182' }}
      >
        <div className="w-full">
          <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
          {title}
          </h4>
          <p className="mb-[40px] max-w-full font-medium  md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">
          {desc} 
            <a className="text-white"> <b> Date </b>:  {startDate }  to  {endDate}</a> 
       
        
          </p>
  
          <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
          {/* <a href="/admin/reports/templates">
            <button className="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70">
              New Report
            </button>
            </a>
            <button
              href=" "
              className="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2"
            >
              Watch Video
            </button> */}

            <a href='#' title='Edit'><MdModeEdit  className=" text-white  h-6 w-6" /></a> 
             <a href='#' title='Download'><IoDownload  className=" text-white  h-6 w-6" /></a> 
             <a href='#' title='Share'><MdShare  className=" text-white  h-6 w-6" /></a>    
            {/* <a href='#' title='Facebook'><IoLogoFacebook  className=" text-white  h-6 w-6" /></a>
              <a href='#' title='Instagram'><IoLogoInstagram className="   text-white h-6 w-6" /></a>
              <a href='#' title='Youtube'><IoLogoYoutube className="  text-white h-6 w-6" /></a>
              <a href='#' title='Tiktok'><IoLogoTiktok className=" text-white  h-6 w-6"/></a>
              <a href='#' title='Twitter'> <IoLogoTwitter className=" text-white  h-6 w-6"/></a>
              <a href='#' title='Whatsapp'><IoLogoWhatsapp className=" text-white  h-6 w-6"/></a> */}
           </div>
           {/* <div className='mt-[10px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10'>  */}
              {/* </div> */}
        </div>
      </div>
    );
  };
  
  export default Profile;
  