
import { useState } from 'react'; 
import Widget from "components/widget/Widget";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { MdAdd, MdBarChart, MdFavorite, MdPeople, MdPeopleOutline, MdTimeline } from 'react-icons/md';
import DailyTraffic from './components/DailyTraffic';
import PieChartCard from './components/PieChartCard';
import { IoMdEye } from 'react-icons/io';
import Profile from './components/Profile';
import Analytics from './components/Analytics';
import Traffic from './components/Traffic';
const CreateReport = () => {

  const [isLoading, setIsLoading] = useState(false); 
  const [startForm, setStartForm] = useState(true); 
  const [data, setData] = useState(null); 


  const handleSubmit = async (e) => {
    e.preventDefault();
    setStartForm(false)
    setIsLoading(true); 
    setTimeout(() => { 
              setData("Your report is ready!"); 
              setIsLoading(false); 
          }, 2000); 
  }

  if (startForm) { 
    return <div className="mt-5 spinner !z-5 relative flex flex-col rounded-xl bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none"> 
           <div className=" grid h-full grid-cols-1 rounded-xl ">
            <div className="px-5 py-5 lg:px-24 lg:py-10   col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 rounded-xl ">
            <h4 className="text-left mb-6 text-2xl font-bold tex t-navy-700 dark:text-white">
             Generate Campaign Report
            </h4> 
            <div className="">
            <form className="form"  method="POST" onSubmit={(e) => handleSubmit(e)}>
            <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-bold"> Select Platform </label>
            <select name="plateform" id="plateform" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
                <option value="All">All</option>
                <option value="Facebook">Facebook</option>
                <option value="Instgram">Instgram</option>
                <option value="Youtube">Youtube</option>
                <option value="Tiktok">Tiktok</option>
              
            </select>

            <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-bold"> Select Metrics </label>
            <select  name="metrix" id="metrix" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
                <option value="All">All</option>
                <option value="Reach">Reach</option>
                <option value="Cost Per Reach">Cost Per Reach</option>
                <option value="Video Views">Video Views</option>
                <option value="Average View Time">Average View Time</option>
                <option value="Likes">Likes</option>
                <option value="Comments">Comments</option>
                <option value="Total Engagements">Total Engagements</option>
                <option value="Engagement Rate">Engagement Rate</option>
                <option value="Cost Per Engagement">Cost Per Engagement</option>
                <option value="Link Clicks">Link Clicks</option>
              
            </select>

              <InputField
                variant="auth"
                extra="mb-3 font-medium"
                label="Channel URL"
                placeholder="https://www.youtube.com/channel........"
                id="channel"
                type="text"
                />

            <h4 className="text-left mt-5 mb-3 text-2xl font-bold tex text-navy-700 dark:text-white">
           
            </h4> 
              <InputField
                variant="auth"
                extra="mb-3 font-medium"
                label="Post with Sponsored Content"
                placeholder="https://www.youtube.com/watch......"
                id="video"
                type="text"
                />
              {/* <div className="mt-5 mb-5"> 
              <button disabled className="flex text-navy-700"> Add More <MdAdd className=' text-navy-700 h-6 w-6'/> </button>
              </div> */}
              <TextField
                variant="auth"
                extra="mb-3 font-medium"
                label="Campaign Summary"
                placeholder="For e.g The campaign successfully garnered 500,000 views, indicating a strong interest from the audience. Viewer engagement was high, with 25,000 likes, showcasing a positive response to the content. Additionally, the campaign saw 10,000 shares, indicating the content's shareability and potential reach beyond the initial audience."
                id="summery"
                type="text"
                rows="5"
                />
                


              <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
                
              <button className="text-black linear rounded-xl bg-brand-500 dark:bg-white px-4 py-2 text-center text-white dark:text-gray-900 font-medium transition duration-200 hover:bg-brand-600 active:bg-brand-600 ">
                  Generate
                </button>
                
                
                <button disabled className="text-black linear rounded-xl bg-white dark:bg-white dark:text-gray-600 px-4 py-2 text-center text-gray-900 dark:text-white-900 font-medium transition duration-200 hover:bg-white hover:text-brand-800 active:bg-white ">
                  Back to templates
                </button>

               

              </div>
              </form>
            
              </div>
              </div>
            </div>
        </div>; 
   } 


  if (isLoading) { 
    return <div className="spinner"> 
           <div className="mt-10 grid h-full grid-cols-1 rounded-xl ">
            <div className="px-24 py-24 col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 rounded-xl  bg-white">
            <h4 className="text-center mb-6 text-2xl font-bold text-navy-700 dark:text-white">
              Hold your breath! 🤩  <br/> We are generating reports for you .....
            </h4> 
            <div className="flex justify-center text-center">
                <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#3f2182">
                      <g fill="none" fill-rule="evenodd">
                          <g transform="translate(1 1)" stroke-width="2">
                              <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                              <path d="M36 18c0-9.94-8.06-18-18-18">
                                  <animateTransform
                                      attributeName="transform"
                                      type="rotate"
                                      from="0 18 18"
                                      to="360 18 18"
                                      dur="1s"
                                      repeatCount="indefinite"/>
                              </path>
                          </g>
                      </g>
                  </svg> 
            </div>
            
              </div>
            </div>
        </div>; 
   } 


  return (
    <div className="mt-10 grid h-full grid-cols-1 gap-5 rounded-xl ">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 rounded-xl  bg-white dark:bg-gray-900">

        <Profile title ={data} desc="" />


    <div className='lg:px-10 lg:py-5 '>
      
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">

        

        <Widget
          icon={<MdFavorite className="h-7 w-7" />}
          title={"Likes"}
          subtitle={"4B"}
        />
         <Widget
          icon={<MdPeopleOutline className="h-7 w-7" />}
          title={"Followers"}
          subtitle={"35 M"}
        />
        <Widget
          icon={<IoMdEye className="h-7 w-7" />}
          title={"Total View"}
          subtitle={"23,899"}
        />    

      <Widget
          icon={<MdTimeline className="h-7 w-7" />}
          title={"Avg View Duration"}
          subtitle={"6,733"}
        />    
         <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Impression"}
          subtitle={"39,646"}
        />   
         <Widget
          icon={<MdPeople className="h-7 w-7" />}
          title={"Unique Viewers"}
          subtitle={"29,646"}
        />     
        </div>

        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>


        <div className="mt-5 grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Analytics />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <Traffic />
        </div>
        </div>
    
        </div>
    </div>



  </div>
  );
};

export default CreateReport;
