import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useMemo } from "react";
import InputField from "components/fields/InputField";

const Youtube = (props) => {
    const { columnsData, tableData } = props;

    return (
        <Card extra={"w-full h-full p-4"}>
            <div className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Youtube Money Calculator
                </div>
                <CardMenu />
            </div>

            <div className="h-full overflow-x-scroll xl:overflow-x-hidden">

            <div className="mb-6 flex items-center  gap-3" /> 
               
            <InputField
                variant="auth"
                extra="mb-3"
                label="Youtube Channel URL"
                placeholder="Youtube Channel URL"
                id="channelurl"
                type="text"
                />

<div className="flex items-center justify-between mb-3">
                <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] mr-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Calculate
                </button>
                <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] ml-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Reset
                </button>
            </div>

            <InputField
                variant="auth"
                extra="mb-3"
                label="Number of total Subscribers"
                placeholder=""
                id="subscriber"
                type="text"
                disabled
                />

            <InputField
                variant="auth"
                extra="mb-3"
                label="Number of Total Video Views"
                placeholder=""
                id="views"
                type="text"
                disabled
                />

            <InputField
                variant="auth"
                extra="mb-3"
                label="Total estimated earnings"
                placeholder=""
                id="earning"
                type="text"
                disabled
                />

            <InputField
                variant="auth"
                extra="mb-3"
                label="Average Earnings per Video"
                placeholder=""
                id="pervideo"
                type="text"
                disabled
                />    
             
            
    


            </div>
        </Card>
    );
};

export default Youtube;
