import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";

import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";

import InfluenceImg from "assets/img/proposal/influencer.jpeg";
import BizImg from "assets/img/proposal/biz.jpeg";
import LogoImg from "assets/img/proposal/logo.jpeg";
import ContentImg from "assets/img/proposal/content.jpeg";
import VideoImg from "assets/img/proposal/video.jpeg";
import YoutubeImg from "assets/img/proposal/youtube.jpeg";
import VirtualImg from "assets/img/proposal/virtual.jpeg";
import WebImg from "assets/img/proposal/web.jpeg";
import InboundImg from "assets/img/proposal/inbound.jpeg";
import { useState, useEffect } from 'react'; 
import mediaKitImg from "../../../assets/img/mkt-4.png"
import List from "./components/List";
import Card from "components/card";
import { MdCopyAll, MdModeEdit, MdShare } from "react-icons/md";
import { IoDownload, IoLogoFacebook, IoLogoInstagram, IoLogoTiktok, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoShare } from 'react-icons/io5';
const CreateMediaKit = () => {

  const [isLoading, setIsLoading] = useState(true); 
  const [startForm, setStartForm] = useState(true); 

  const [isLinkGenerated, setIsLinkGenerated] = useState(true); 
  const [data, setData] = useState(null); 


  const handleSubmit = async (e) => {
    e.preventDefault();
    setStartForm(false)
    setIsLoading(true); 
    setTimeout(() => { 
              setData("Your Proposal is ready!"); 
              setIsLoading(false); 
          }, 2000); 
  }


  if (startForm) { 
    return <div className="mt-5 spinner !z-5 relative flex flex-col rounded-xl bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none"> 
           <div className=" grid h-full grid-cols-1 rounded-xl  ">
            <div className="px-5 py-5 lg:px-24 lg:py-10   col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 rounded-xl ">
            <h4 className="text-left mb-6 text-2xl font-bold tex t-navy-700 dark:text-white">
             Generate Proposal 
            </h4> 
            <form className="form"  method="POST" onSubmit={(e) => handleSubmit(e)}>
            <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-2">
            

            <TextField
                variant="auth"
                extra="mb-3 font-medium"
                label="About me"
                placeholder="I am a passionate and dedicated social media influencer who thrives on creating engaging content that resonates with my audience."
                id="about"
                type="text"
                rows="4"
                />


              <TextField
                variant="auth"
                extra="mb-3 font-medium"
                label="Why work with me ?"
                placeholder="Work with me because I bring creativity, authenticity, and a genuine connection with my audience. I'll help your brand reach and resonate with a wider audience"
                id="work"
                type="text"
                rows="4"
                />

            <div>
            <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-bold"> Select Platform </label>
            <select name="plateform" id="plateform" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
                <option value="All">All</option>
                <option value="Facebook">Facebook</option>
                <option value="Instgram">Instgram</option>
                <option value="Youtube">Youtube</option>
                <option value="Tiktok">Tiktok</option>
              
            </select>
            </div>

            <div>
              <InputField
                variant="auth"
                extra="mb-3 font-medium"
                label="Channel URL"
                placeholder="https://www.youtube.com/channel........"
                id="channel"
                type="text"
                />
            </div>

            <div>
              <TextField
                variant="auth"
                extra="mb-3 font-medium"
                label="Service to offer"
                placeholder="For e.g I offer a variety of services, including content creation, graphic design, photography, video production, social media management, SEO content, copywriting, podcast production, brand collaborations, consulting, webinars, virtual assistance, and more. These services cater to diverse needs for enhancing online presence and audience engagement."
                id="about"
                type="text"
                rows="6"
                />   
              </div>           
          
           
             
            
              </div>
              <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
                
                <div className="">
                 <button className="text-black linear rounded-xl bg-brand-500 dark:bg-white px-4 py-2 text-center text-white dark:text-gray-900 font-medium transition duration-200 hover:bg-brand-600 active:bg-brand-600 ">
                     Generate
                   </button>
                   
                   
                   <button disabled className="text-black linear rounded-xl bg-white dark:bg-white dark:text-gray-600 px-4 py-2 text-center text-gray-900 dark:text-white-900 font-medium transition duration-200 hover:bg-white hover:text-brand-800 active:bg-white ">
                     Back to templates
                   </button>
   
                   </div>   
   
                 </div>
              </form>
              </div>
            </div>
        </div>; 
   } 


  if (isLoading) { 
    return <div className="spinner"> 
           <div className="mt-10 grid h-full grid-cols-1 rounded-xl ">
            <div className="px-24 py-24 col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 rounded-xl  bg-white">
            <h4 className="text-center mb-6 text-2xl font-bold text-navy-700 dark:text-white">
              Hold your breath! 🤩  <br/> We are generating proposal for you .....
            </h4> 
            <div className="flex justify-center text-center">
                <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#3f2182">
                      <g fill="none" fill-rule="evenodd">
                          <g transform="translate(1 1)" stroke-width="2">
                              <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                              <path d="M36 18c0-9.94-8.06-18-18-18">
                                  <animateTransform
                                      attributeName="transform"
                                      type="rotate"
                                      from="0 18 18"
                                      to="360 18 18"
                                      dur="1s"
                                      repeatCount="indefinite"/>
                              </path>
                          </g>
                      </g>
                  </svg> 
            </div>
            
              </div>
            </div>
        </div>; 
   } 


   if(isLinkGenerated){
    return <div className="spinner"> 
           <div className="mt-10 grid h-full grid-cols-1 rounded-xl ">
            <div className="px-24 py-20 col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 rounded-xl  bg-white">
            <h2 className="text-center mb-6 text-4xl font-bold text-navy-700 dark:text-white">
            Awesome 🤩  <br/> 
            </h2>
            <h4 className="text-center mb-6 text-3xl font-bold text-navy-700 dark:text-white">
            Your Proposal is ready !            
            </h4> 
            <div className="flex  flex-col justify-center text-center">
            <div className="flex justify-center ">
             
                  <input type="url"  value="https://nyla.finna.club" disabled placeholder="https://nyla.finna.club" className="theme-input-link text-center text-3xl font-bold text-navy-700" />
            
              </div>
              <div className="py-5 flex justify-center " >
              <a href="#" alt="copy" title="copy"  className="mr-5  font-medium">
                    <span>Copy</span><MdCopyAll className="h-6 w-6 mt-2" alt="copy" title="copy" /></a>
                 
                  <a href='#' title='Share' className="mr-5  font-medium">
                    <span>Share</span> <MdShare  className=" h-6 w-6 mt-2" /></a>

                    <a href='#' onClick={(e) => setIsLinkGenerated(false)} title='Edit' className="mr-5  font-medium">
                    <span>Edit</span> <MdModeEdit  className=" h-6 w-6 mt-2" /></a>  
                 
              </div>
            </div>
            
              </div>
            </div>
        </div>; 
   }



  return (
    <div className="mt-10 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3 rounded-xl ">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 rounded-xl  bg-white">

      <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            {data}
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>

            <div className="flex justify-between ">
            <a href='#' title='Edit'><MdModeEdit  className="mr-5 h-6 w-6" /></a> 
             {/* <a href='#' title='Download'><IoDownload  className="   h-6 w-6" /></a> 
             <a href='#' title='Share'><MdShare  className="   h-6 w-6" /></a> */}
            <a href="#" alt="copy" title="copy" ><MdCopyAll className="h-6 w-6" alt="copy" title="copy" /></a>
    
            <input type="url"  value="https://nyla.finna.club" disabled placeholder="https://nyla.finna.club" className="theme-input text-center" />
         
  
            </div>
            </li>
    
          </ul>
        </div>
        <img src={mediaKitImg} alt="" className="w-full rounded-xl  object-contain"/>      
    </div>

    {/* right side section */}

    <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">

    <Card extra={"!z-5 overflow-hidden"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-xl p-3">
        <div className="py-2 text-lg font-bold text-navy-700 dark:text-white">
        <a href='#'><MdShare  className=" text-brand-500  h-6 w-6" /></a>
        </div>

        <a href='#'><IoLogoFacebook  className=" text-brand-500  h-6 w-6" /></a>
        <a href='#'><IoLogoInstagram className=" text-brand-500  h-6 w-6" /></a>
        <a href='#'><IoLogoYoutube className=" text-brand-500  h-6 w-6" /></a>
        <a href='#'><IoLogoTiktok className=" text-brand-500  h-6 w-6"/></a>
        <a href='#'> <IoLogoTwitter className=" text-brand-500  h-6 w-6"/></a>
        <a href='#'><IoLogoWhatsapp className=" text-brand-500  h-6 w-6"/></a>

      </div>
      <h4 className="ml-4 mt-5 text-xl font-bold text-navy-700 dark:text-white">
            Available Templates
          </h4>    
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Influencer Proposal"
            author="Sam"
            price=""
            image={InfluenceImg}
          />      

          
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Business Proposal"
            author="Tin"
            price=""
            image={BizImg}
          />
         

      </Card>

      {/* <HistoryCard /> */}
    </div>
  </div>
  );
};

export default CreateMediaKit;
