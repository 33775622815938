import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useMemo } from "react";


const Influencer = (props) => {
  const { columnsData, tableData } = props;

  return (
    <Card extra={"w-full h-full p-4"}>
      <div class="relative flex items-center justify-between">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
            Influencer Pricing Calculator
        </div>
        <CardMenu />
      </div>

      <div class="h-full overflow-x-scroll xl:overflow-x-hidden">

<div class="h-full overflow-x-scroll xl:overflow-x-hidden">

    <div className="mb-6 flex items-center  gap-3" />


    {/* <form action="#" method="post"> */}

    <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium"> Platform </label>
    <select name="plateform" id="plateform" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
        <option value="All">All</option>
        <option value="Facebook">Facebook</option>
        <option value="Instgram">Instgram</option>
        <option value="Youtube">Youtube</option>
        <option value="Tiktok">Tiktok</option>
       
    </select>


    <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium"> Category</label>
    <select name="category" id="category" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
        <option value="Any">Any</option>
        <option value="Lifestyle">Lifestyle</option>
        <option value="Fashion">Fashion</option>
        <option value="Travel">Travel</option>
        <option value="Health & Fitness">Health & Fitness</option>
        <option value="Music & Dance">Music & Dance</option>
        <option value="Athlete & Sports">Athlete & Sports</option>
        <option value="Other">Other</option>
    </select>


    <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium"> Followers</label>
    <select name="followers" id="followers" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
        <option value="Any">Any</option>
        <option value="0-1k">0-1k</option>
        <option value="1k-10k">1k-10k</option>
        <option value="50k-100k">50k-100k</option>
        <option value="100k-500k">100k-500k</option>
        <option value="1m-5m">1m-5m</option>
        <option value="10m+">10m+</option>

    </select>



    <div className="flex items-center justify-between">
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] mr-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Calculate
                    </button>
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] ml-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Reset
                    </button>
                </div>

    {/* </form> */}
</div>
</div>    
    </Card>
  );
};

export default Influencer;
