
const projectStatus = [
    {
        id : 1,
        status : "ACTIVE"
    },
    {
        id : 2,
        status : "INACTIVE"
    },
    {
        id : 3,
        status : "HOLD"
    },
    {
        id : 4,
        status : "DONE"
    }
]

const dashboardData = {
    githubUsername: "akshay619-dev",
    name: "Finna! Dashboard",
    desc: "The Future of Work for Content Creators",
    avatarUrl: "",

    projects :[
        {
            title : "Xion X",
            desc : "",
            image : "",
            medium : ["Instagram" , "Facebook" , "Youtube" , "Tiktok"],
            author : "Melisa Wong",
            createdTime: "15th Oct 2023",
            estimatedTime : "30th Nov 2023",
            approxPayment : 3000,
            status : projectStatus.status ,
        },
        {
            title : "Santo G ",
            desc : "",
            image : "",
            medium : ["Instagram" , "Facebook"],
            author : "Tomy Wong",
            createdTime: "1st Nov 2023",
            estimatedTime : "6th Dec 2023",
            approxPayment : 2200,
            status :  projectStatus.status  ,
        },
        {
            title : "Destor Z ",
            desc : "",
            image : "",
            medium : ["Instagram"],
            author : "Sam lee",
            createdTime: "25th Nov 2023",
            estimatedTime : "30th Dec 2023",
            approxPayment : 1200,
            status :  projectStatus.status  ,
        },
        {
            title : "Moto Z",
            desc : "",
            image : "",
            medium : ["Instagram"],
            author : "Sam lee",
            createdTime: "12th Nov 2023",
            estimatedTime : "6th Dec 2023",
            approxPayment : 2300,
            status :  projectStatus.status  ,
        },
        {
            title : "Takashi Le ",
            desc : "",
            image : "",
            medium : ["Instagram"],
            author : "Sam lee",
            createdTime: "3rd Dec 2023",
            estimatedTime : "13th Dec 2023",
            approxPayment : 2030,
            status :  projectStatus.status  ,
        }
    ],

    invoices :[
        {
            title : "Invoice#03941",
            desc : "Anthony Petterson",
            image : "",
            medium : ["Instagram" , "Facebook" , "Youtube" , "Tiktok"],
            author : "Melisa park",
            createdTime: "30th Dec 2023",
            estimatedTime : "30th Nov 2023",
            approxPayment : 395.00,
            status : projectStatus.status ,
        },
        {
            title : "Invoice#0942",
            desc : "Sant G",
            image : "",
            medium : ["Instagram" , "Facebook"],
            author : "Tomy Wong",
            createdTime: "1st Nov 2023",
            estimatedTime : "6th Dec 2023",
            approxPayment : 2200,
            status :  projectStatus.status  ,
        },
        {
            title : "Invoice#01241",
            desc : "Divor",
            image : "",
            medium : ["Instagram"],
            author : "Stev Macxi",
            createdTime: "25th Nov 2023",
            estimatedTime : "30th Dec 2023",
            approxPayment : 1200,
            status :  projectStatus.status  ,
        },
        {
            title : "Invoice#03641",
            desc : "Misco",
            image : "",
            medium : ["Instagram"],
            author : "Tanish Bajaj",
            createdTime: "12th Nov 2023",
            estimatedTime : "6th Dec 2023",
            approxPayment : 2300,
            status :  projectStatus.status  ,
        },
        {
            title : "Invoice#08741",
            desc : "Taczo",
            image : "",
            medium : ["Instagram"],
            author : "John lee",
            createdTime: "3rd Dec 2023",
            estimatedTime : "13th Dec 2023",
            approxPayment : 2030,
            status :  projectStatus.status  ,
        }
    ]

}

export { dashboardData };