import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useMemo } from "react";
import InputField from "components/fields/InputField";



const Mortgage = (props) => {
  const { columnsData, tableData } = props;

  return (
    <Card extra={"w-full h-full p-4"}>
      <div class="relative flex items-center justify-between">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
            Mortgage Calculator
        </div>
        <CardMenu />
      </div>

      <div class="h-full overflow-x-scroll xl:overflow-x-hidden">

      <div className="mb-6 flex items-center  gap-3" /> 


        {/* <form action="#" method="post"> */}
 
        <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium"> Property Type </label>
        <select name="property" id="property" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
          <option value="private">Private Property</option>
          <option value="private">HDB</option>
        </select>


        <label className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium"> Building Status</label>
        <select name="status" id="status" className="mt-2 mb-3 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white">
          <option value="private">Completed</option>
          <option value="private">Under Construction</option>
        </select>

        <InputField
          variant="auth"
          extra="mb-3"
          label="Loan Amount"
          placeholder="Loan Amount"
          id="loanamount"
          type="number"
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Interest Rate"
          placeholder="Interest Rate"
          id="ir"
          type="number"
          min="0"
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Loan Tenure (in years)"
          placeholder="Loan Tenure"
          id="loantenure"
          type="number"
        />


<div className="flex items-center justify-between">
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] mr-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Calculate
                    </button>
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] ml-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Reset
                    </button>
                </div>

        {/* </form> */}
      </div>
    </Card>
  );
};

export default Mortgage;
