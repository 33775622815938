import ChatImg from "../../../assets/img/chat.png"
// import HistoryCard from "./components/HistoryCard";
const Messages = () => {



  return (
    <div className="mt-10 grid h-full grid-cols-1">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        <img src={ChatImg} alt="" className="w-full rounded-xl  object-contain"/>      
    </div>


    {/* <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
      <HistoryCard />
    </div> */}
  </div>
  );
};

export default Messages;
