import InvoiceImg from "../../../assets/img/invoice.png"
import HistoryCard from "./components/HistoryCard";
const ViewInvoice = () => {



  return (
    <div className="mt-10 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3 rounded-xl ">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        <img src={InvoiceImg} alt="" className="w-full rounded-xl  object-contain"/>      
    </div>

    {/* right side section */}

    <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
      <HistoryCard />
    </div>
  </div>
  );
};

export default ViewInvoice;
