import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";


import TimeImg from "assets/img/reports/time.jpeg";
import TaskImg from "assets/img/reports/task.jpeg";
import TaxImg from "assets/img/reports/tax.jpeg";
import ContentImg from "assets/img/reports/content.jpeg";
import SalesImg from "assets/img/reports/sales.jpeg";
import ClienteImg from "assets/img/reports/client.jpeg";
import ExpenseImg from "assets/img/reports/expense.jpeg";
import CTRImg from "assets/img/reports/ctr.jpeg";
import WebImg from "assets/img/reports/web.jpeg";
import CampaignImg from "assets/img/reports/campaign.jpeg";

import List from "./components/List";

const Templates = () => {
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
     
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Browse Templates
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href="/admin/reports/create"
              >
                Create from scratch
              </a>
            </li>
     
          </ul>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
        <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Campaign Report"
            author="Max"
            price=""
            image={CampaignImg}
          />
         
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Time Tracking Report"
            author="Sam"
            price=""
            image={TimeImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Task Completion Report"
            author="Tin"
            price=""
            image={TaskImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Client Feedback Report"
            author="David"
            price=""
            image={ClienteImg}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Expense Report"
            author="Selena"
            price=""
            image={ExpenseImg}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Content Performance Report"
            author="Muzak"
            price=""
            image={ContentImg}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Click-Through Rate (CTR) Report"
            author="Kaliz"
            price=""
            image={CTRImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Tax Deduction Report"
            author="Shwan"
            price="0.91"
            image={TaxImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Website Analytics Report"
            author="Dexter"
            price="0.91"
            image={WebImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Sales Forecast Report"
            author="Ray"
            price="0.91"
            image={SalesImg}
          />
        </div>
      </div>
    </div>
  );
};

export default Templates;
