import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useMemo } from "react";
import InputField from "components/fields/InputField";

const Insurance = (props) => {
    const { columnsData, tableData } = props;

    return (
        <Card extra={"w-full h-full p-4"}>
            <div className="relative flex items-center justify-between">
                <div className="text-xl font-bold text-navy-700 dark:text-white">
                    Insurance Calculator
                </div>
                <CardMenu />
            </div>

            <div className="h-full overflow-x-scroll xl:overflow-x-hidden">

            <div className="mb-6 flex items-center  gap-3" /> 
               <h3 className="text-xl font-bold text-navy-700 dark:text-white"> Coming soon .... </h3>
            </div>
        </Card>
    );
};

export default Insurance;
