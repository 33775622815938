import Banner from "../default/components/Banner";
const Setting = () => {
  return (
    <div className="">
      <div className="mt-3 grid h-full grid-cols-1">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      <Banner title="Coming soon..."  />

      </div>
     
      </div>
    
    </div>
  );
};

export default Setting;
