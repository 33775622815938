import CheckTable from "./components/CheckTable";

import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "./variables/columnsData";
import tableDataDevelopment from "./variables/tableDataDevelopment.json";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataColumns from "./variables/tableDataColumns.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import DevelopmentTable from "./components/DevelopmentTable";
import ColumnsTable from "./components/ColumnsTable";
import ComplexTable from "./components/ComplexTable";
import Mortgage  from "./components/Mortgage";
import AdEarning from "./components/AdEarning";
import Freelancer from "./components/Freelancer";
import Insurance from "./components/Insurance";
import Influencer from "./components/Influencer";
import Youtube from "./components/Youtube";


const Calculators = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
      <Influencer
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />

        <Freelancer
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />

      </div>

      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
      <AdEarning
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
        
      <Youtube   
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}/>  
       
      </div>


      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-2">
        <Mortgage
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
         
         <Insurance
          columnsData={columnsDataDevelopment}
          tableData={tableDataDevelopment}
        />
      </div>

  
 
    </div>
  );
};

export default Calculators;
