import nft1 from "assets/img/nfts/NftBanner1.png";
import { MdCamera, MdCases } from "react-icons/md";

import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
const CreateProject = () => {
  return (
    <div className="mt-3 grid h-full  grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        <div
          className="flex w-full flex-col rounded-[20px] bg-[#FFFFFF] px-[30px] py-[30px] md:px-[64px] md:py-[56px]"
        >
      <div className="w-full">
      <form>
      <InputField
          variant="auth"
          extra="mb-3"
          label="Project Name"
          placeholder=""
          id="project"
          type="text"
        />
        <TextField
          variant="auth"
          extra="mb-3"
          label="Project description"
          placeholder=""
          id="projecdesc"
          type="text"
        />

        <div className="flex justify-start"> 
        <button
          href=" "
          className="linear mt-4 mr-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Create
        </button>
        <button
          href=" "
          className="linear mt-4 flex items-center justify-center rounded-xl bg-brand-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Cancel
        </button>
        </div>
        </form>
      </div>
    </div>
    </div>
    </div>
  );
};

export default CreateProject;
