import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import Invoices from "views/admin/invoices";
import MediaKit from "views/admin/mediakit";
import Projects from "views/admin/projects";
import Reports from "views/admin/reports";
import Payments from "views/admin/payments";
import Resources from "views/admin/resources";
import Community from "views/admin/community";
import Messages from "views/admin/messages";
import HelpSupport from "views/admin/helpsupport";
import Setting from "views/admin/settings";
// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {

  MdPerson,
  MdLock,
  MdChat,
  MdSettings,
  MdHelpCenter,
  MdCalculate,
  MdPayments,
  MdAnalytics,
  MdInventory,

  MdLocalShipping,

  MdPortrait,
  MdDashboard,
  MdOutlineCommute,
  MdGroups,
  MdLogout,
  MdWebAsset,
  MdOpenInNew,
  MdLibraryBooks,
  MdAddToDrive,
} from "react-icons/md";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Proposals",
    layout: "/admin",
    path: "proposal",
    icon: <MdPortrait className="h-6 w-6" />,
    component: <MediaKit />,
    secondary: true,
  },
  {
    name: "Projects",
    layout: "/admin",
    path: "projects",
    icon: <MdInventory className="h-6 w-6" />,
    component: <Projects />,
    secondary: true,
  },
  {
    name: "Invoices",
    layout: "/admin",
    icon: <MdLocalShipping className="h-6 w-6" />,
    path: "invoices",
    component: <Invoices />,
  },
  {
    name: "Reports",
    layout: "/admin",
    icon: <MdAnalytics className="h-6 w-6" />,
    path: "reports",
    component: <Reports />,
  },
  {
    name: "Payments",
    layout: "/admin",
    icon: <MdPayments className="h-6 w-6" />,
    path: "payments",
    component: <Payments />,
  },
  {
    name: "Resource Center",
    layout: "/admin",
    icon: <MdCalculate className="h-6 w-6" />,
    path: "resource-center",
    component: <Resources />,
  },
  {
    name: "Community",
    layout: "/admin",
    path: "community",
    icon: <MdGroups className="h-6 w-6" />,
    component: <Community />,
  },
  {
    name: "Toolkit",
    layout: "/admin",
    path: "toolkit",
    icon: <MdAddToDrive className="h-6 w-6" />,
    component: <HelpSupport />,
  },
  {
    name: "Messages",
    layout: "/admin",
    path: "messages",
    icon: <MdChat className="h-6 w-6" />,
    component: <Messages />,
  },
  {
    name: "My Profile",
    layout: "/admin",
    path: "myprofile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },

  {
    name: "Settings",
    layout: "/admin",
    path: "setting",
    icon: <MdSettings className="h-6 w-6" />,
    component: <Setting />,
  },

  {
    name: "Log out",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLogout className="h-6 w-6" />,
    component: <SignIn />,
  }
];
export default routes;
