import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import React, { useMemo } from "react";
import InputField from "components/fields/InputField";

const Freelancer = (props) => {
  const { columnsData, tableData } = props;

  return (
    <Card extra={"w-full h-full p-4"}>
      <div class="relative flex items-center justify-between">
        <div class="text-xl font-bold text-navy-700 dark:text-white">
            Freelancer Rate Calculator
        </div>
        <CardMenu />
      </div>

      <div className="h-full overflow-x-scroll xl:overflow-x-hidden">

            <div className="mb-6 flex items-center  gap-3" /> 

               <InputField
                variant="auth"
                extra="mb-3"
                label="Rent or home payments"
                placeholder="Rent or home payments"
                id="rent"
                type="number"
                />
                <InputField
                variant="auth"
                extra="mb-3"
                label="Utilities"
                placeholder="Utilities"
                id="utilities"
                type="number"
                />
                <InputField
                variant="auth"
                extra="mb-3"
                label="Health Insurance / Other"
                placeholder="Health Insurance / Other"
                id="healthinsurance"
                type="number"
                />

                <div className="flex items-center justify-between">
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] mr-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Calculate
                    </button>
                    <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] ml-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Reset
                    </button>
                </div>
               
            </div>
    </Card>
  );
};

export default Freelancer;
