import Banner from "./components/Banner";
import NFt2 from "assets/img/nfts/Nft2.png";
import NFt4 from "assets/img/nfts/Nft4.png";
import NFt3 from "assets/img/nfts/Nft3.png";
import NFt5 from "assets/img/nfts/Nft5.png";
import NFt6 from "assets/img/nfts/Nft6.png";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";


import InfluenceImg from "assets/img/proposal/influencer.jpeg";
import BizImg from "assets/img/proposal/biz.jpeg";
import LogoImg from "assets/img/proposal/logo.jpeg";
import ContentImg from "assets/img/proposal/content.jpeg";
import VideoImg from "assets/img/proposal/video.jpeg";
import YoutubeImg from "assets/img/proposal/youtube.jpeg";
import VirtualImg from "assets/img/proposal/virtual.jpeg";
import WebImg from "assets/img/proposal/web.jpeg";
import InboundImg from "assets/img/proposal/inbound.jpeg";




import List from "./components/List";

const Templates = () => {
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
     
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Browse Templates
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href="/admin/proposal/create"
              >
                Create from scratch
              </a>
            </li>
     
          </ul>
        </div>

        {/* NFTs trending card */}
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-3">
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Influencer Proposal"
            author="Sam"
            price=""
            image={InfluenceImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Business Proposal"
            author="Tin"
            price=""
            image={BizImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Logo & Branding Proposal"
            author="David"
            price=""
            image={LogoImg}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Content Writing Proposal"
            author="Selena"
            price=""
            image={ContentImg}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Inbound Marketing Proposal"
            author="Muzak"
            price="0.91"
            image={InboundImg}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Video Production Proposal"
            author="Kaliz"
            price="0.91"
            image={VideoImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="YouTube Marketing Proposal"
            author="Shwan"
            price="0.91"
            image={YoutubeImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Virtual Assistant Proposal"
            author="Dexter"
            price="0.91"
            image={VirtualImg}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Simple Web Design Proposal"
            author="Ray"
            price="0.91"
            image={WebImg}
          />
        </div>
      </div>
    </div>
  );
};

export default Templates;
