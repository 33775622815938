import Banner from "../default/components/Banner";
import List from "./components/List";
import NDAImg from "../../../assets/img/tools/nda.jpeg";
import PhotographImgImg from "../../../assets/img/tools/photographer.jpeg";
import MarketingImg from "../../../assets/img/tools/marketing.jpeg";
import InteriorImg from "../../../assets/img/tools/interior.jpeg";
import HistoryCard from "../reports/components/HistoryCard"
const Contract = () => {
  return (
    <div className="">

    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      <Banner title="Create and send  contracts in minutes."   desc ="Use our available templates for project agreements, NDAs, and more, or use your own"  image="" link1 ="#" link2 ="#" buttonLabel1="New Contract" buttonLabel2="Watch video"  />

      {/* Card widget */}

      </div>
      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1"> 
          <HistoryCard/>
      </div>
      </div>
      <div className="mt-3 grid h-full grid-cols-1">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      
      <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Browse Templates
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href="/admin/proposal/create"
              >
                Create from scratch
              </a>
            </li>
     
          </ul>
        </div>

      
      </div>

      <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-4">
      <List
            bidders={[]}
            title="Interior Designer"
            author="John"
            price=""
            image={InteriorImg}
          />
          <List
            bidders={[]}
            title="Non-disclosure agreement (NDA)"
            author="Sam"
            price=""
            image={NDAImg}
          />
          <List
            bidders={[]}
            title="Commercial Photographer"
            author="Tin"
            price=""
            image={PhotographImgImg}
          />
     
          <List
            bidders={[]}
            title="Marketing Consultant"
            author="David"
            price=""
            image={MarketingImg}
          />

         </div> 


      </div>
    
    </div>
  );
};

export default Contract;
