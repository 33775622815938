import { useState, React } from "react";
import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import Sitelogo from "../../assets/img/logo.png"
import { Redirect ,useNavigate } from "react-router-dom";
export default function SignIn() {
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState({ isSuccessful: false, message: '' });
    

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formEle = document.querySelector("form");
    const formDatab = new FormData(formEle);

    try {
       if(e.target[0].value === "admin@finna.club" && e.target[1].value === "@dmin@723"){
        
        setResponseMessage({
          isSuccessful: true,
          message: 'Login Successful...',
          });
        navigate("/admin");
       }

       setResponseMessage({
        isSuccessful: false,
        message: 'Incorrect Credentials ....',
        });
      
    } catch (e) {
        console.log(e);
        setResponseMessage({
        isSuccessful: false,
        message: 'Oops something went wrong. Please try again.',
        });

    }
 }

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[6vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">

          <img
              className="w-40 mb-5"
              src={Sitelogo}
              alt="Finna! Dashboard"
            />
        {/* <h4 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4> */}
        <h2 className="mb-2.5 text-2xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h2>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>

        <form className="form"  method="POST" onSubmit={(e) => handleSubmit(e)}>
        {/* Email */}

        <span className="text-red-400 text-center">{responseMessage.message != '' && responseMessage.message} </span>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          name="email"
          type="text"
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          name="password"
          type="password"
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        {/* <Link to="/admin" className="mt-0 w-max lg:pt-10"> */}
        <button className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>
        {/* </Link> */}


        </form>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
  );
}
