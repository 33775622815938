import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import CreateMediaKit from "views/admin/mediakit/create";
import CreateProject from "views/admin/projects/create";
import Templates from "views/admin/mediakit/templates";
import ReportTemplates from "views/admin/reports/templates";
import CreateReport from "views/admin/reports/create"
import routes from "routes.js";
import ViewInvoice from "views/admin/invoices/view";
import Contract from "views/admin/helpsupport/contract";
import IntegrationView from "views/admin/messages/integration";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Finna"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />

                <Route
                 name = "Create Proposal"
                  path="proposal/create"
                  element={<CreateMediaKit />}
                />

                <Route
                 name = "Browse Proposal"
                  path="proposal/templates"
                  element={<Templates />}
                />


                <Route
                 name = "Create Project"
                  path="projects/create"
                  element={<CreateProject />}
                />

                <Route
                 name = "Create Invoice"
                  path="invoices/create"
                  element={<CreateProject />}
                />
                <Route
                 name = "View Invoice"
                  path="invoices/view"
                  element={<ViewInvoice />}
                />

                <Route
                 name = "Browse reports"
                  path="reports/templates"
                  element={<ReportTemplates />}
                />


                <Route
                 name = "Create report"
                  path="reports/create"
                  element={<CreateReport />}
                />


                <Route
                 name = "Create contract"
                  path="toolkit/contract"
                  element={<Contract />}
                />


                <Route
                 name = "Create integration"
                  path="messages/integration"
                  element={<IntegrationView />}
                />

              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
