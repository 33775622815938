import Card from "components/card";
import React from "react";

const Traffic = () => {
  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
        Audience Reach
        </h4>
        <p className="mt-2 px-2 text-base text-gray-600">
      
        </p>
      </div>
      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Traffic Source Type</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Youtube Search : 55.7% 
            Suggested videos  : 3.9%
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Traffic Source : External </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Google Search : 55.7%
            Facebook : 3.9% <br/>
            Instagram : 27%
          </p>
        </div>


        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Country</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            USA : 50% <br/> 
            Cananda : 25%  <br/>
            India : 10% <br/> 
            Brazil : 3% <br/> 
            Singapore : 12%
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Watch time </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            Non Subscriber : 57%
            Subscriber : 43%
          </p>
        </div>

      </div>
    </Card>
  );
};

export default Traffic;
