import Banner from "./components/Banner";
import NFt2 from "assets/img/nfts/Nft2.png";
import NFt4 from "assets/img/nfts/Nft4.png";
import NFt3 from "assets/img/nfts/Nft3.png";
import NFt5 from "assets/img/nfts/Nft5.png";
import NFt6 from "assets/img/nfts/Nft6.png";
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import avatar7 from "assets/img/avatars/avatar7.png";
import avatar8 from "assets/img/avatars/avatar8.png";
import avatar9 from "assets/img/avatars/avatar9.png";
import avatar10 from "assets/img/avatars/avatar10.png";
import avatar11 from "assets/img/avatars/avatar11.png";

import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";
import HistoryCard from "./components/HistoryCard";
import TopCreatorTable from "./components/TableTopCreators";
import NftCard from "components/card/NftCard";
import List from './components/List'


import { dashboardData} from '../../../database/data'

const Projects = () => {

  return (
    <div className=" grid h-full grid-cols-1 gap-5 ">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
        <div className="mb-4 mt-5 flex flex-col justify-between px-4 md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            
          </h4>
          {/* <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <a
                className="text-base font-medium text-brand-500 hover:text-brand-500 dark:text-white"
                href=" "
              >
                Older 
              </a>
            </li>
          </ul> */}
        </div>

        <div className="z-20 grid grid-cols-2 gap-5 md:grid-cols-4">
                    
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Selena Wizko"
            author="Content Creator"
            price=""
            image={avatar1}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Alberto "
            author="Real Estate Agent"
            price=""
            image={avatar9}
          />
          <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Tory Lee"
            author="Freelance Graphic Designer"
            price=""
            image={avatar10}
          />
       
            <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Daniel"
            author="Content Creator And Video Wizard!"
            price=""
            image={avatar5}
          />
     
            <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Brad"
            author="Landscape, Travel, Sustainability"
            price=""
            image={avatar7}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Kim"
            author="Freelancer , Graphics design"
            price=""
            image={avatar4}
          />
               <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Sabrina"
            author="I Make Beauty,Nails,And Fashion Videos"
            price=""
            image={avatar3}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Aaman"
            author="Consultant , Agency Owner"
            price=""
            image={avatar8}
          />
           <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Sheron"
            author="Wellness Coach , Freelancer , Motivator"
            price=""
            image={avatar6}
          />
            {/* <List
            bidders={[avatar1, avatar2, avatar3]}
            title="Mia"
            author="Content Creator"
            price=""
            image={avatar11}
          /> */}
        </div>   
      </div>

    </div>
  );
};

export default Projects;
